
import * as VueRouter from "vue-router";
import Home from "@/views/pages/Home.vue";
import Login from "@/views/pages/Login.vue";
import Register from "@/views/pages/Register.vue";
import UserList from "@/views/users/UserList.vue";
import AppartmentsList from "@/views/appartements/List.vue";
import AppartmentShow from "@/views/appartements/Show.vue";
import ImeubleList from "@/views/imeubles/List.vue";
import ContractAdd from "@/views/contracts/Add.vue";
import ContractList from "@/views/contracts/List.vue";
import ContractExpire from "@/views/contracts/ExpireList.vue";
import BankList from "@/views/bank/List.vue";
import BankShow from "@/views/bank/Show.vue";




const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,

    },

    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },

    {
      path: "/users",
      name: "Users",
      component: UserList,
    },

    {
      path: "/appartments",
      name: "Appartments",
      component: AppartmentsList,
    },

    {
      path: "/appartments/show/:id",
      name: "Appartments.show",
      component: AppartmentShow,
    },

    {
      path: "/contracts",
      name: "Contract",
      component: ContractList,
    },

    {
      path: "/contractsExpire",
      name: "Contracts.expire",
      component: ContractExpire,
    },

   

    {
      path: "/contract/add/:id",
      name: "Contract.add",
      component: ContractAdd,
    },

    {
      path: "/imeubles",
      name: "Imeubles",
      component: ImeubleList,
    },

    {
      path: "/bank",
      name: "Bank",
      component: BankList,
    },

    {
      path: "/bank/show/:id",
      name: "Bank.show",
      component: BankShow,
    },
  ],
});

// const app = createApp(App)
// app.use(router).mount('#app')
// app.mount('app')

export default router;
