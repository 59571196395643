<template>
    <nav @click="toggleSidebar" class="navbar navbar-expand navbar-theme">
        <a class="sidebar-toggle d-flex me-2">
            <i class="hamburger align-self-center"></i>
        </a>

        <div class="navbar-collapse collapse">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item dropdown ms-lg-2">
                    <a class="nav-link dropdown-toggle position-relative" href="#" id="userDropdown"
                        data-bs-toggle="dropdown">
                        <i class="align-middle fas fa-cog"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                        <a class="dropdown-item" href="#"><i class="align-middle me-1 fas fa-fw fa-user"></i>
                            View Profile</a>
                        <a class="dropdown-item" href="#"><i class="align-middle me-1 fas fa-fw fa-comments"></i>
                            Contacts</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'HorizBarre',
    mounted (){
        this.toggleSidebar()
    },
    
    methods: {
        toggleSidebar() {
            document.body.classList.toggle('sidebar-collapsed');
        }
    }
}
</script>

<style scoped>
/* Ajoutez ici les styles spécifiques à votre composant si nécessaire */
</style>
