<template>
    <div class="wrapper">
        <Sidebar />
        <div class="main">
            <HorizBarre />

            <main class="content">
                <div class="container-fluid">
                    <div class="header mb-4">
                        <h1 class="header-title">Informations Sur L'appartement</h1>
                    </div>

                    <!-- Vérifie si appartment et appartment.data existent -->
                    <div v-if="appartment && appartment.data">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="card shadow-sm">
                                    <div class="card-header bg-primary text-white">
                                        Informations sur l'Appartement
                                    </div>
                                    <div class="card-body">
                                        <h3>Appartement {{ appartment.data.appartname }} de L'imeuble {{
                                            appartment.data.building.name }}</h3>
                                        <h4><strong>Adresse:</strong> {{ appartment.data.building.address || 'Aucune adresse fournie' }}</h4>
                                        <h4>
                                            <strong>Localisation:</strong> {{ appartment.data.building.city }} ( {{
                                                appartment.data.building.district }} )
                                        </h4>

                                        <template v-if="appartment.data.current_contract">
                                            <h4 v-for="item in appartment.data.current_contract.items" :key="item.id">
                                                <strong v-if="item.frequency == 'ONCE'">{{ item.rental_item.name }}:
                                                    <span class="text-success">{{ item.amount }} FCFA </span></strong>

                                            </h4>
                                        </template>

                                        <h4 v-if="appartment.data.current_contract">Solde:
                                            <span
                                                :class="{ 'text-success': appartment.data.current_solde >= 0, 'text-danger': appartment.data.current_solde < 0 }">
                                                {{ appartment.data.current_solde }} FCFA
                                            </span>
                                        </h4>

                                        <div v-if="appartment.data.current_contract">
                                            <button @click="showModal = true" class="btn btn-success">
                                                Ajouter Payement
                                            </button>
                                          
                                            <div v-if="showModal" tabindex="-1" z-index="999"
                                                @click.self="showModal = false" class="modal" id="centeredModalSuccess"
                                                role="dialog" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered modal-lg"
                                                    role="document">
                                                    <!-- agrandit le modal en ajoutant `modal-lg` -->
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title">Ajouter paiement</h5>
                                                            <button type="button" class="btn-close"
                                                                @click="showModal = false" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body m-3">
                                                            <h4 class="card-header mb-3">Entrez les montants reçus</h4>
                                                            <div v-for="(item, index) in appartment.data.current_contract.items"
                                                                :key="item.id" class="col-md mb-3">
                                                                <div v-if="item.frequency == 'MONTHLY_POSTPAID' || item.frequency == 'MONTHLY_PREPAID'"
                                                                    class="mb-0">
                                                                    <div class="input-group">
                                                                        <button class="btn btn-success" type="button">{{
                                                                            item.rental_item.name }}</button>
                                                                        <input type="number"
                                                                            @keypress="isNumber($event)"
                                                                            v-model="paymentItems[index].amount"
                                                                            class="form-control"
                                                                            placeholder="Entrez le montant">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="paymentNote" class="form-label">Note</label>
                                                                <textarea v-model="note" id="paymentNote"
                                                                    class="form-control" rows="3"
                                                                    placeholder="Ajouter une note"></textarea>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="paymentNote" class="form-label">Mode de Paiement</label>
                                                                <input type="text"
                                                                    v-model="payment_method"
                                                                    class="form-control"
                                                                    placeholder="Entrez le Mode de Paiement">
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="paymentNote" class="form-label">Reference</label>
                                                                <input type="text"
                                                                    v-model="reference"
                                                                    class="form-control"
                                                                    placeholder="Entrez la Reference">
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="paymentImage" class="form-label">Ajouter une
                                                                    image</label>
                                                                <input type="file" @change="handleImageUpload"
                                                                    id="paymentImage" class="form-control"
                                                                    accept="image/*">
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" @click="showModal = false"
                                                                class="btn btn-secondary">Fermer</button>
                                                            <button type="button" @click="savePayments()"
                                                                class="btn btn-success">Enregistrer</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div v-if="showSuccessToast" class="custom-toast">
                                                <strong>Succès !</strong> Paiements enregistrés avec succès !
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 mb-3">
                                <div class="card shadow-sm">
                                    <div class="card-header bg-primary text-white">
                                        Informations sur le client actuel
                                    </div>
                                    <div v-if="appartment.data.current_contract" class="card-body">
                                        <h3><strong>Nom:</strong> {{ appartment.data.current_contract.user.firstname ||
                                            'Aucun Prénom fourni' }} {{ appartment.data.current_contract.user.lastname
                                                || 'Aucun Nom fourni' }}</h3>
                                        <h4><strong>Email:</strong> {{ appartment.data.current_contract.user.email ||
                                            'Aucune adresse fournie' }} </h4>
                                        <h4><strong>Téléphone:</strong>
                                            {{ appartment.data.current_contract.user.phonenumber || 'Aucun numero de téléphone fourni' }}</h4>

                                        <h4>Statut:
                                            <span
                                                :class="{ 'text-success': appartment.data.current_contract.user.active == 'yes', 'text-danger': appartment.data.current_contract.user.active == 'no' }">
                                                {{ appartment.data.current_contract.user.active == 'yes' ? 'Actif' :
                                                    'Non Actif' }}
                                            </span>
                                        </h4>

                                        <div v-if="appartment.data.current_contract">
                                            <button @click="showNoteModal = true" class="btn btn-info">
                                                Ajouter Une Note
                                            </button>

                                            <!-- Modal -->
                                            <div v-if="showNoteModal" tabindex="-1" z-index="999"
                                                @click.self="showNoteModal = false" class="modal"
                                                id="centeredModalSuccess" role="dialog" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title">Ajouter une Note sur le contrat En cours</h5>
                                                            <button type="button" class="btn-close"
                                                                @click="showNoteModal = false"
                                                                aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body m-3">
                                                            <h4 class="card-header mb-3">Entrez la note</h4>
                                                            <div class="form-floating">
                                                                <textarea v-model="note" class="form-control"
                                                                    placeholder="Leave a comment here"
                                                                    id="floatingTextarea1"
                                                                    style="height: 100px"></textarea>
                                                                <label for="floatingTextarea1">Votre Note</label>
                                                            </div>
                                                        </div>

                                                        <div class="form-control mb-3">
                                                            <label for="paymentImage" class="form-label">Ajouter une image</label>
                                                            <input type="file" @change="handleImageUpload" id="paymentImage" class="form-control" accept="image/*">
                                                        </div>

                                                        <div class="modal-footer">
                                                            <button type="button" @click="showNoteModal = false"
                                                                class="btn btn-secondary">Fermer</button>
                                                            <button type="button" @click="saveNote"
                                                                class="btn btn-success">Enregistrer</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="showSuccessNoteToast" class="custom-toast">
                                                <strong>Succès !</strong> Note enregistré avec succès !
                                            </div>


                                        </div>
                                    </div>
                                    <div v-else>
                                        <p class="text-center mt-3">Aucun Client pour cet appartement
                                            <br>
                                            <router-link
                                                :to="{ name: 'Contract.add', params: { id: appartment.data.id } }">
                                                <button class="text-center btn btn-info">
                                                    Ajouter Un contrat
                                                </button>
                                            </router-link>
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="appartment.data.current_contract" class="row">
                            <div class="col-12 mb-3">
                                <div class="card shadow-sm">
                                    <div
                                        class="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                                        <span>Informations sur les éléments du contrat en cours</span>
                                        <button class="btn btn-light btn-sm" @click="toggleContractInfo">
                                            {{ showContractInfo ? 'Réduire' : 'Voir plus' }}
                                        </button>
                                    </div>
                                    <div v-show="showContractInfo" class="card-body">
                                        <div v-if="appartment.data.current_contract">
                                            <h4><strong>Status: </strong>
                                                <span
                                                    :class="{ 'text-primary': appartment.data.current_contract.status == 'IN_PROGRESS', 'text-secondary': appartment.data.current_contract.status == 'TERMINATED', 'text-success': appartment.data.current_contract.status == 'TERMINATED' }">
                                                    {{ appartment.data.current_contract.status == 'IN_PROGRESS' ? 'EN COURS' :
                                                    appartment.data.current_contract.status == 'TERMINATED' ? 'TERMINÉ'
                                                    : 'COMPLÉTÉ' }}
                                                </span>
                                            </h4>
                                            <h4><strong>Date de début :</strong> {{
                                                appartment.data.current_contract.started_at }}</h4>


                                            <!-- <h5>Items du contrat</h5> -->
                                            <table class="table table-bordered table-hover text-center">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th>Item de location</th>
                                                        <th>Montant</th>
                                                        <th>Fréquence</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in appartment.data.current_contract.items"
                                                        :key="item.id">
                                                        <td>{{ item.rental_item.name }}</td>
                                                        <td>{{ item.amount }} FCFA</td>
                                                        <td>{{ item.frequency === 'MONTHLY_PREPAID' ? 'MENSUEL PRÉPAYÉ'
                                                            : item.frequency ===
                                                            'MONTHLY_POSTPAID' ? 'MENSUEL POSTPAYÉ' : 'UNE FOIS'
                                                            }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div v-else>
                                            <p class="text-center mt-3">Aucun contrat en cours pour cet appartement.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div v-if="appartment.data.current_contract" class="row">
                            <div class="col-12 mb-3">
                                <div class="card shadow-sm">
                                    <div
                                        class="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                                        <span> Informations sur les événements du contrat en cours</span>
                                        <button class="btn btn-light btn-sm" @click="toggleEvents">
                                            {{ eventsExpanded ? 'Réduire' : 'Voir plus' }}
                                        </button>
                                    </div>

                                    <div class="card-body" v-show="eventsExpanded">
                                        <div v-if="appartment.data.current_contract">
                                            <h5>Événements du contrat</h5>
                                            <ul class="list-unstyled">
                                                <li v-for="event in paginatedEvents" :key="event.id" class="mb-3">
                                                    <strong>Type: </strong>
                                                    <span :class="{ 'text-info': event.event_type == 'NOTE', 'text-warning': event.event_type == 'BILLING' && appartment.data.status[event.id].status != 'Payée' ,'text-success': (event.event_type == 'BILLING' && appartment.data.status[event.id].status == 'Payée') ||  event.event_type == 'PAYMENT_RECEIVED'}">
                                                        {{ translateEventType(event.event_type) }} <span v-if="event.event_type == 'BILLING' && appartment.data.status[event.id]">( {{ appartment.data.status[event.id].status }} )</span>
                                                    </span> 
                                                    <template v-if="event.event_type != 'NOTE'"> -
                                                        <strong>Montant: </strong>
                                                        <span class="text-primary">{{ event.amount }}
                                                            FCFA</span>
                                                            <template v-if="event.event_type == 'BILLING' && appartment.data.status[event.id]">
                                                                    <br>
                                                                    <strong>Montant dû: </strong>
                                                                    <span class="text-primary">{{ appartment.data.status[event.id].reste }}
                                                                    FCFA</span>
                                                            </template>
                                                        </template>
                                                    <template v-if="event.contract_rental_item">
                                                        <br>
                                                        <strong>Motif: </strong> <span class="text-primary">{{
                                                            event.contract_rental_item.rental_item.name }}</span>
                                                    </template>
                                                    <br>
                                                    <strong>Note: </strong> {{ event.note || 'Aucune note' }}
                                                    <br>
                                                    <small><strong>Date: </strong> {{ event.event_time }}</small>
                                                    
                                                    <template v-if="event.event_type == 'BILLING' && appartment.data.status[event.id]">
                                                        <br>
                                                        <small>
                                                            <button class="btn btn-info btn-sm" @click="generateInvoce(appartment.data.id,event.id)">
                                                                Generer Facture
                                                            </button>
                                                        </small>
                                                    </template>

                                                    <template v-if="event.image">
                                                        <br>
                                                        <template v-if="event.image.endsWith('.pdf')">
                                                            <a :href="`https://www.ultrabail.net/backend/${event.image}`" target="_blank" class="mt-2">
                                                                Télécharger le PDF
                                                            </a>
                                                        </template>
                                                        <template v-else>
                                                            <a :href="`https://www.ultrabail.net/backend/${event.image}`" target="_blank">
                                                                <img :src="`https://www.ultrabail.net/backend/${event.image}`" alt="Image associée" class="mt-2" style="max-width: 100px; max-height: 100px;">
                                                            </a>
                                                        </template>
                                                    </template>

                                                    <!-- <template v-if="event.image">
                                                        <br>
                                                        <img :src="`https://www.ultrabail.net/backend/${event.image}`" alt="Image associée" class="mt-2" style="max-width: 100px; max-height: 100px;">
                                                    </template> -->
                                                    <br>        
                                                    <button class="btn btn-warning btn-sm mt-2" @click="openEditModal(event)">Modifier</button>
                                                </li>
                                            </ul>

                                           
                                            <div v-if="showEditModal" tabindex="-1" z-index="999" @click.self="showEditModal = false" class="modal" role="dialog" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title">Modifier l'événement</h5>
                                                            <button type="button" class="btn-close" @click="showEditModal = false" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body m-3">
                                                            <h4 class="card-header mb-3">Modifiez les informations de l'événement</h4>

                                                            <input type="hidden" id="eventId" v-model="editingEvent.id" class="form-control">

                                                            <div class="mb-3">
                                                                <label for="eventType" class="form-label">Type d'événement</label>
                                                                <input type="text" readonly id="eventType" v-model="editingEvent.event_type" class="form-control" placeholder="Entrez le type d'événement">
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="eventAmount" class="form-label">Montant</label>
                                                                <input type="number" id="eventAmount" v-model="editingEvent.amount" class="form-control" placeholder="Entrez le montant">
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="eventNote" class="form-label">Note</label>
                                                                <textarea id="eventNote" v-model="editingEvent.note" class="form-control" rows="3" placeholder="Ajouter une note"></textarea>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="eventDate" class="form-label">Date de l'événement</label>
                                                                <input type="date" id="eventDate" v-model="editingEvent.event_time" class="form-control">
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="eventImage" class="form-label">Modifier l'image</label>
                                                                <input type="file" @change="handleEditImageUpload" id="eventImage" class="form-control" accept="image/*">
                                                            </div>
                                                        </div>

                                                        <div class="modal-footer">
                                                            <button type="button" @click="showEditModal = false" class="btn btn-secondary">Fermer</button>
                                                            <button type="button" @click="updateEvent()" class="btn btn-success">Enregistrer les modifications</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="showUpdateSuccessToast" class="custom-toast">
                                                <strong>Succès !</strong> Event modifié avec succès !
                                            </div>


                                            

                                            <!-- Pagination Controls -->
                                            <nav aria-label="Page navigation">
                                                <ul class="pagination justify-content-center">
                                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                        <button class="page-link" @click="prevPage">Précédent</button>
                                                    </li>
                                                    <li class="page-item" v-for="page in totalPages" :key="page"
                                                        :class="{ active: currentPage === page }">
                                                        <button class="page-link" @click="goToPage(page)">{{ page
                                                            }}</button>
                                                    </li>
                                                    <li class="page-item"
                                                        :class="{ disabled: currentPage === totalPages }">
                                                        <button class="page-link" @click="nextPage">Suivant</button>
                                                    </li>
                                                </ul>
                                            </nav>

                                        </div>
                                        <div v-else>
                                            <p class="text-center mt-3">Aucun contrat en cours pour cet appartement.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p>Chargement des informations de l'appartement...</p>
                    </div>
                </div>
            </main>

            <FooterPage />
        </div>
    </div>
</template>



<script>

import Sidebar from '../../components/Sidebar.vue'
import FooterPage from '../../components/Footer.vue'
import HorizBarre from '../../components/HorizBarre.vue'
import fetch from '../../utils/fetch';

export default {
    name: "AppartmentShow",
    components: {
        Sidebar,
        FooterPage,
        HorizBarre
    },
    data() {
        return {
            appartment: {
                data: null,
                current: null,
                isUpdate: false,
                isLoading: true,
                isError: false,
                errorMessage: "",
            },
            showContractInfo: false,
            eventsExpanded: false,
            showModal: false,
            showNoteModal: false,
            paymentItems: [],
            note: '',
            payment_method: '',
            reference: '',
            paymentImage: null,
            showSuccessToast: false,
            showSuccessNoteToast: false,
            showUpdateSuccessToast: false,
            currentPage: 1,
            itemsPerPage: 5,
            showEditModal: false,
            editingEvent: {
                id: '',
                event_type: '',
                amount: '',
                note: '',
                event_time: '',
                image: null
            },

        };
    },

    watch: {
        'appartment.data.current_contract.items': function (newItems) {
            if (newItems) {
                this.paymentItems = newItems.map(item => ({
                    contrat_rental_item_id: item.id,
                    amount: ['MONTHLY', 'MONTHLY_POSTPAID', 'MONTHLY_PREPAID'].includes(item.frequency) ? item.amount : 0
                }));
            }

        }
    },
    computed: {
        totalPages() {
            if (this.appartment.data.current_contract && this.appartment.data.current_contract.events) {
                return Math.ceil(this.appartment.data.current_contract.events.length / this.itemsPerPage);
            }
            return 0;
        },
        paginatedEvents() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            return this.appartment.data.current_contract.events.slice(start, start + this.itemsPerPage);
        },
    },
    mounted() {
        this.getAppartement(this.$route.params.id);

    },
    methods: {

        handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.paymentImage = file;
            }
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        goToPage(page) {
            this.currentPage = page;
        },
        showSuccessNotification() {
            this.showSuccessToast = true;
            setTimeout(() => this.showSuccessToast = false, 3000);
        },

        showUpdateSuccessNotification() {
            this.showUpdateSuccessToast = true;
            setTimeout(() => this.showUpdateSuccessToast = false, 3000);
        },
        showSuccessNoteNotification() {
            this.showSuccessNoteToast = true;
            setTimeout(() => this.showSuccessNoteToast = false, 3000);
        },
        toggleContractInfo() {
            this.showContractInfo = !this.showContractInfo;
        },
        toggleEvents() {
            this.eventsExpanded = !this.eventsExpanded;
        },
        translateEventType(eventType) {
            switch (eventType) {
                case 'NOTE':
                    return 'INFORMATION';
                case 'PAYMENT_RECEIVED':
                    return 'PAIEMENT REÇU';
                case 'BILLING':
                    return 'FACTURATION';
                default:
                    return eventType;
            }
        },
        getTypeClass(eventType) {
            switch (eventType) {
                case 'NOTE':
                    return 'text-info';
                case 'PAYMENT_RECEIVED':
                    return 'text-success';
                case 'BILLING':
                    return 'text-warning';
                default:
                    return 'text-secondary';
            }
        },
        getAppartement(id) {
            this.isLoading = true;
            fetch({ url: `appartments/${id}` })
                .then(({ data }) => {
                    this.appartment.data = data;
                    this.appartment.isError = false;
                    this.appartment.isLoading = false;

                })
                .catch(() => {
                    this.appartment.isError = true;
                    this.appartment.errorMessage = "Une erreur est survenue, réessayez";
                })
                .finally(() => {
                    this.appartment.isLoading = false;
                });
        },
        savePaymentsOld() {
            const paymentData = this.paymentItems
                .filter(item => item.amount)
                .map(item => ({
                    contract_id: this.appartment.data.current_contract.id,
                    event_type: "PAYMENT_RECEIVED",
                    note: this.note || "PAYMENT_RECEIVED",
                    amount: item.amount,
                    image: this.paymentImage,
                    contrat_rental_item_id: item.contrat_rental_item_id
                }));

            fetch({ url: `contract-events`, method: 'POST', data: paymentData })
                .then(response => {
                    this.paymentItems.forEach(item => item.amount = '');
                    this.showModal = false;
                    this.showSuccessNotification();
                    this.getAppartement(this.$route.params.id);
                })
                .catch(error => {
                    console.error(error);
                    alert("Une erreur est survenue.");
                });
        },
        savePayments() {
            this.paymentItems
                .filter(item => item.amount)
                .forEach(item => {
                    const formData = new FormData();
                    formData.append("contract_id", this.appartment.data.current_contract.id);
                    formData.append("event_type", "PAYMENT_RECEIVED");
                    formData.append("note", this.note || "PAYMENT_RECEIVED");
                    formData.append("payment_method", this.payment_method);
                    formData.append("reference", this.reference);                  
                    formData.append("amount", item.amount);
                    formData.append("contrat_rental_item_id", item.contrat_rental_item_id);

                    if (this.paymentImage) {
                        formData.append("image", this.paymentImage);
                    }

                    fetch({ url: `contract-events`, method: 'POST', data: formData })
                    .then(response => {
                            this.paymentItems.forEach(item => item.amount = '');
                            this.note = "";
                            this.paymentImage = "";
                            this.showModal = false;
                            this.showSuccessNotification();
                            this.getAppartement(this.$route.params.id);
                        })
                    .catch(error => {
                        console.error(error);
                        alert("Une erreur est survenue.");
                    });
                });
        },

        generateInvoce(appart_id,event_id) {
            const formData = new FormData();
                formData.append("appart_id", appart_id);
                formData.append("event_id", event_id);
                    
                
                fetch({ url: `invoice`, method: 'POST', data: formData })
                .then(response => {
                    if (response.data.pdf_link) {
                        window.open(response.data.pdf_link, '_blank');
                    }
                })
                .catch(error => {
                    console.error(error);
                    alert("Une erreur est survenue.");
                });
        },

        saveNote() {

            if (this.note.trim() === '') {
                alert('Veuillez entrer une note avant de soumettre.');
                return;
            }
            const formData = new FormData();
                formData.append("contract_id", this.appartment.data.current_contract.id);
                formData.append("event_type", "NOTE");
                formData.append("note", this.note || "NOTE");
                formData.append("amount",0);
                formData.append("contrat_rental_item_id", null);

                if (this.paymentImage) {
                    formData.append("image", this.paymentImage);
                }

            fetch({ url: `contract-events-note`, method: 'POST', data: formData })
                .then(response => {
                    this.note = "";
                    this.paymentImage = "";
                    this.showNoteModal = false;
                    this.showSuccessNoteNotification();
                    this.getAppartement(this.$route.params.id);
                })
                .catch(error => {
                    console.error(error);
                    alert("Une erreur est survenue.");
                });
        },

        openEditModal(event) {
            this.editingEvent = { ...event };
            this.showEditModal = true;
        },
        handleEditImageUpload(event) {
            const file = event.target.files[0];
            this.editingEvent.image = file; // Stocke l'image sélectionnée
        },
        updateEvent() {
            try {
                const formData = new FormData();
                formData.append('event_type', this.editingEvent.event_type);
                formData.append('amount', this.editingEvent.amount);
                formData.append('note', this.editingEvent.note);
                formData.append('event_time', this.editingEvent.event_time);
                formData.append('_method', "PUT");

                if (this.editingEvent.image) {
                    formData.append('image', this.editingEvent.image);
                }

                fetch({ url: `contract-events/${this.editingEvent.id}`, method: 'POST', data: formData })
                    .then(response => {
                        this.showEditModal = false;
                        this.showUpdateSuccessNotification();
                        this.getAppartement(this.$route.params.id);
                    })
                    .catch(error => {
                        console.error(error);
                        alert("Une erreur est survenue.");
                    });
                
            } catch (error) {
                console.error("Erreur de mise à jour :", error);
                alert("Une erreur est survenue lors de la mise à jour de l'événement.");
            }
        }
    }
}
</script>

<style scoped>
.card {
    border: 1px solid #ddd;
    border-radius: 8px;
}

.card-body {
    padding: 20px;
}

.text-success {
    color: green;
    font-weight: bold;
}

.text-danger {
    color: red;
    font-weight: bold;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-size: 18px;
    color: #555;
}

.spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.table {
    margin-top: 20px;
}

.building-info {
    text-align: right;
}

.card-header {
    font-weight: bold;
}

.header-title {
    font-size: 1.5rem;
}

.text-success {
    color: #28a745;
}

.text-danger {
    color: #dc3545;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    flex-basis: 600px;
    padding: spacing(4);

    border-radius: 0%;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.custom-toast {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #28a745;
    /* Couleur de fond verte */
    color: white;
    /* Couleur du texte */
    padding: 15px 30px;
    /* Espacement interne */
    border-radius: 8px;
    /* Coins arrondis */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    /* Ombre portée */
    animation: fadeOut 3s forwards;
    /* Animation de disparition */
    font-size: 1.1em;
    /* Taille de police */
    text-align: center;
    /* Alignement du texte */
    max-width: 80%;
    /* Largeur maximale */
    width: 300px;
    /* Largeur fixe */
    z-index: 9999;
    /* S'assurer qu'il est au-dessus des autres éléments */
    transition: transform 0.3s ease;
    /* Transition pour l'apparition */
    transform: translate(-50%, -50%) scale(1.05);
    /* Légère échelle pour l'effet */
}

/* Effet de sortie */
@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.8);
    }
}

/* Ajouter un effet au survol */
.custom-toast:hover {
    cursor: pointer;
    background-color: #218838;
    /* Couleur légèrement plus foncée au survol */
}
</style>